<template>
  <v-layout row wrap no-gutters class="margin-top-25">
    <v-flex md10>
      <h1 class="font-36">
        <template v-if="estate.status === 'sold'">
          {{ soldTitle() }}
        </template>
        <template v-else>
          {{ estate.title }}
        </template>
      </h1>
      <p class="font-20 margin-tb-10" v-if="estate.about">
        <vue-markdown>{{ estate.about }}</vue-markdown>
      </p>
      <!-- <p class="font-20 margin-tb-10" v-if="estate.description">
        {{ estate.description }}
      </p> -->
    </v-flex>
  </v-layout>

</template>

<script type="text/javascript">
import EstateMixins from '@/common/mixins/estates/estate-mixins'

export default {
  name: 'EstateDetailsHeader',

  props: {
    estate: {
      type: Object,
      required: true
    }
  },

  mixins: [EstateMixins],

  methods: {
    soldTitle () {
      const createdDate = this.$moment(this.estate.created_at)
      const soldDate = this.$moment(this.estate.sold_date)
      const duration = this.$options.filters.duration([createdDate.diff(soldDate, 'days'), 'days'], 'humanize')

      return this.$t('estates.estate_sold_in', { duration })
    }
  }
}

</script>
